import { useState } from "react";
import rome from "./assets/images/rome.jpeg";
import maldives from "./assets/images/maldives.jpeg";
import france from "./assets/images/paris.jpeg";

import Modal from "./Modal";
import Contact from "./Contact";

import "./App.css";

function App() {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Modal open={modalVisible} close={() => setModalVisible(false)}>
        <Contact />
      </Modal>

      <header>
        <nav>
          <div>
            <p className="logo">BoxTrip</p>
          </div>
          <div>
            <button
              className="btn_primary"
              onClick={() => setModalVisible(true)}
            >
              Log In
            </button>
          </div>
        </nav>
        <div className="header_hero">
          <h1>Explore world heritage destinations all around the world</h1>
          <p>
            Experience world class travel experiences with our world class
            travel partners.
          </p>

          <div>
            <button
              className="btn_primary"
              onClick={() => setModalVisible(true)}
            >
              Contact Us
            </button>
          </div>
        </div>
      </header>

      <section class="bestT">
        <h2>Our Best Tour</h2>
        <p>
          These are locations where we recommend for your team to feel
          healthier, happier and less stressed than before. Sourced from the
          Global Retirement Index
        </p>

        <div class="cities">
          <div>
            <img src={rome} alt="rome" />
            <p>Rome, Italy</p>
            <p>$ 3000</p>
          </div>
          <div>
            <img src={maldives} alt="maldives" />
            <p>Maldives</p>
            <p>$ 5900</p>
          </div>
          <div>
            <img src={france} alt="paris" />
            <p>Paris, France</p>
            <p>$ 8870</p>
          </div>
        </div>
      </section>

      <footer>
        <div>
          <p>Copyright © 2020 BoxTrip</p>
        </div>
        <div>
          <h2>Address</h2>
          <p>Nigeria- Plot 2 & 3, Admiralty Way, Lekki Phase 1 100001, Lekki</p>
          <p>
            Kenya- Jimkan House, Woodvale Grove, Westlands District, Nairobi
          </p>
        </div>
      </footer>
    </>
  );
}

export default App;
